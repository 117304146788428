export default {
  namespaced: true,
  state: {
    settingData: null
  },
  mutations: {
    saveSetting(store, data) {
      store.settingData = data
    }
  }
}
