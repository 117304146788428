import { Fields } from '@/config/applicationInxi.js'
import _ from 'lodash'

let cloneFields = _.cloneDeep(Fields)
export default {
  namespaced: true,
  state: {
    fields: cloneFields,
    productInfo: null
  },
  mutations: {
    setData(state, data) {
      state[data[0]] = data[1]
    }
  }
}
