const now = new Date()
let date = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate()
const Fields = {
  information: {
    title: '法人信息',
    tip: '请完善法人信息',
    type: 'text',
    fields: {
      name: {
        type: 'text',
        align: 'left',
        value: '',
        placeholder: '请输入法人姓名',
        require: true
      },
      tel: {
        type: 'tel',
        align: 'left',
        value: '',
        maxlength: 11,
        placeholder: '请输入法人手机号',
        button: '发送验证码',
        require: true
      },
      smsCode: {
        type: 'number',
        align: 'left',
        value: '',
        placeholder: '请输入验证码',
        maxlength: 6,
        require: true
      },
      idCardNum: {
        type: 'number',
        align: 'left',
        value: '',
        placeholder: '请输入法人身份证号',
        maxlength: 18,
        require: true
      },
      companyName: {
        type: 'text',
        align: 'left',
        value: '',
        placeholder: '请输入企业全称',
        require: true
      },
      companyDate: {
        type: 'picker',
        picker: 'date',
        align: 'left',
        value: '',
        placeholder: '请输入企业成立日期',
        require: true,
        readonly: true,
        minDate: new Date(1980, 0, 1),
        maxDate: new Date(2099, 10, 1)
      }
    }
  },
  idCard: {
    title: '身份证',
    tip: '请上传身份证正反面（共两张）',
    require: true,
    type: 'image/*',
    rotate: false,
    value: []
  },
  businessLicense: {
    title: '营业执照',
    tip: '请上传营业执照',
    require: true,
    type: 'image/*',
    value: []
  }
}

export { Fields }
