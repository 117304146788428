import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    fields: null,
    productInfo: null
  },
  mutations: {
    setData(state, data) {
      state[data[0]] = data[1]
    }
  }
}
