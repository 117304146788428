export default {
  namespaced: true,
  state: {
    articleId: '',
    currentArticle: null,
    calculatorDetail: null
  },
  mutations: {
    setData(state, data) {
      state[data[0]] = data[1]
    }
  }
}
