<template>
  <div class="wrap">
    <van-image :src="url"></van-image>
    <div class="count-down" @click="skip">跳过{{ countDown }}s</div>
  </div>
</template>

<script>
  import api from '@/api/api'
  export default {
    props: {
      url: {
        type: String,
        default: '',
        required: true
      }
    },
    data() {
      return {
        countDown: 3, // 倒计时
        timer: null // 定时器
      }
    },

    created() {
      this.timer = setInterval(() => {
        if (this.countDown) {
          --this.countDown
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
      this.getLocation() // 吴赟说每次打开应用的时候就去请求定位信息
    },

    beforeDestroy() {
      this.timer = null
    },

    methods: {
      skip() {
        this.$store.commit('home/saveSplash')
      },

      async getLocation(resolve) {
        this.$http
          .get(api.getLocation)
          .then((res) => {
            this.$http.cookies.set('location', res.location)
            resolve()
          })
          .catch((e) => {})
      }
    }
  }
</script>

<style lang="less" scoped>
  @import '~@/assets/css/custom.less';
  .wrap {
    position: fixed;
    z-index: 9999;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    .van-image {
      width: 100%;
      height: 100%;
    }
    .count-down {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      padding: 3px 7px;
      text-align: center;
      line-height: 150%;
      font-size: @fz-14;
      color: white;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 16px;
    }
  }
</style>
