export default {
  namespaced: true,
  state: {
    search: '',
    posterId: ''
  },
  mutations: {
    setData(state, data) {
      state[data[0]] = data[1]
    }
  }
}
