import { Fields } from '@/config/fields.js'
import _ from 'lodash'

// let cloneFields = _.cloneDeep(Fields)
export default {
  namespaced: true,
  state: {
    fields: null,
    borrower: null,
    actives: 0,
    productInfo: null
  },
  mutations: {
    changeFields(state, fields) {
      state.fields = fields
    },

    changeActive(state, active) {
      state.actives = active
    },

    changeProductInfo(state, info) {
      state.productInfo = info
    },

    changeBorrower(state, info) {
      state.borrower = info
    }
  }
}
