export default {
  namespaced: true,
  state: {
    storageStatus: ''
  },
  mutations: {
    setData(state, data) {
      state[data[0]] = data[1]
    }
  }
}
