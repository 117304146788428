<template>
  <div id="app" @drop="onDrop" v-cloak>
    <!-- 路由的meta没有keepAlive，则使用这个route-view -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Splash :url="splashRes.imageUrl" v-if="!splash && splashRes && splashRes.status"></Splash>
    <keep-alive>
      <!-- 如果某个路由的meta的keepAlive为true，则为需要保存组件状态，此时使用这个route-view -->
      <router-view v-if="$route.meta.keepAlive"></router-view>
      <r-tabbar
        v-if="showTabbar"
        inactiveColor="rgba(168, 168, 168, 1)"
        :activeColor="colors.theme"
        :tabbar="tabbar"
        :tabIndex="tabIndex"
        @changePosition="changePosition"
        @changeTab="clickTab"
      />
    </keep-alive>
    <Fab v-if="showFab" :historyLength="historyLength"></Fab>
  </div>
</template>

<script>
  import Fab from '@/components/fab/Fab.vue'
  import Splash from '@/components/splash/Splash.vue'
  import { mapState } from 'vuex'
  import { COLORS } from '@/utils/enum.js'
  import config from '../package.json'
  import api from '@/api/api'
  import Axios from 'axios'
  import { mixin } from '@/utils/mixin.js'
  const TABS = ['index', 'user', 'team', 'discovery']
  export default {
    data() {
      return {
        colors: COLORS,
        tabbar: [],
        timer: null, // 定时器
        splashRes: null,
        historyLength: 1,
        showFab: false
      }
    },
    components: {
      Fab,
      Splash
    },

    mixin: [mixin],

    created() {
      if (this.$route.query.openid) {
        this.$store.commit('home/saveSplash')
      }
      this.getSplash()
      this.setIgnorePaths()
      this.setConfigs()
      this.getStorage()
      this.setStorage()
    },

    watch: {
      $route: {
        deep: true,
        handler(newVal) {
          if (this.$route.name === 'index') {
            this.showFab = false
          } else {
            this.showFab = true
          }
          // if (TABS.includes(newVal.name) || (this.$route.name === 'agent' && this.$route.query.id)) {
          //   this.showFab = true
          // } else {
          //   this.showFab = true
          // }
          this.$store.commit('home/saveFirstUrl', this.$route.fullPath)
          this.historyLength = window.history.length
        }
      }
    },

    computed: {
      /**
       * 根据路由的名字，切换tabIndex并判断是否显示tabbar
       * @return {Boolean} 返回是否显示tabbar的布尔值
       */
      showTabbar() {
        switch (this.$route.name) {
          case 'index':
            this.$store.commit('home/changeTabIndex', 0)
            break
          case 'agent':
            this.$store.commit('home/changeTabIndex', 0)
            break
          case 'team':
            this.$store.commit('home/changeTabIndex', 1)
            break
          case 'discovery':
            this.$store.commit('home/changeTabIndex', 2)
            break
          case 'user':
            this.$store.commit('home/changeTabIndex', 3)
            break
          case 'orderlist':
            this.$store.commit('home/changeTabIndex', 3)
            break
          default:
            break
        }
        const routes = [...TABS, 'orderlist']
        return routes.includes(this.$route.name) || (this.$route.name === 'agent' && this.$route.query.id)
      },

      ...mapState('home', ['tabIndex', 'splash', 'firstUrl'])
    },

    methods: {
      // 获取真实有效微信签名URL
      // getWechatSignUrl(to) {
      //   if (isIos()) {
      //     return window.location.href.split('#')[0]
      //   } else {
      //     // 此处$appHost需要自行处理
      //     return (location.protocol + '//' + location.host + '/m' + (to.fullPath === '/' ? '' : to.fullPath)).split('#')[0]
      //   }
      // },

      getSplash() {
        this.$http.get(api.getSplash).then((res) => {
          this.splashRes = res
          this.timer = setTimeout(() => {
            this.$store.commit('home/saveSplash')
          }, 3000)
        })
      },

      setIgnorePaths() {
        this.$http.setAuthIgnorePaths(['/product/detail', '/user/login', '/agent/startImage', '/address/location', '/wechat/browserConfig'])
      },

      /**
       * 点击tab栏修改tabbar激活索引
       * @param {Number} tabIndex 当前点击的tabbar索引
       */
      clickTab(tabIndex) {
        this.$store.commit('home/changeTabIndex', tabIndex)
      },

      changePosition() {},

      /**
       * 添加自定义事件configs的监听，当core中的响应拦截器中截取到response有configs数据时，就会dispatch这里的configs事件
       */
      setConfigs() {
        const _this = this
        window.addEventListener(
          'configs',
          function(e) {
            window.configs = e.configs.tabbar
            if (!_this.tabbar.length) {
              window.configs.forEach((item) => {
                item.img[1] += '?' + config.version
                _this.tabbar.push(item)
              })
            }
            if (!window.configs) {
              _this.tabbar = []
            }
          },
          false
        )
      },

      /**
       * 获取sessionStorage
       */
      getStorage() {
        if (sessionStorage.getItem('store')) {
          let storage = JSON.parse(sessionStorage.getItem('store'))
          storage.home.wxSignUrl = ''
          this.$store.replaceState(Object.assign({}, this.$store.state, storage))
        }
      },

      /**
       * 当页面触发unload事件之前，将store中的数据存储到sessionStorage中
       */
      setStorage() {
        window.addEventListener('pagehide', () => {
          this.$store.commit('home/setWechatSignUrl', '', false)
          sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        })
      },

      onDrop() {}
    }
  }
</script>

<style lang="less">
  // @font-face {
  //   font-family: 'sy-m';
  //   src: url('./assets/sy.otf');
  // }

  // @font-face {
  //   font-family: 'sy-r';
  //   font-weight: 400;
  //   src: url('./assets/sy.otf');
  // }

  // @font-face {
  //   font-family: 'sy-b';
  //   font-weight: 700;
  //   src: url('./assets/sy.otf');
  // }

  // @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-top)) {
  //   body {
  //     padding-bottom: constant(safe-area-inset-bottom);
  //     padding-bottom: env(safe-area-inset-top);
  //   }
  // }

  #app {
    position: relative;
    // overflow: scroll;
    height: 100vh;
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Helvetica Neue', STHeiti, 'Microsoft Yahei', Tahoma, Simsun, sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    background-color: white;
    -webkit-overflow-scrolling: touch;
  }

  // .ignore-body {
  //   max-width: 750px;
  // }

  .rui-tabbar {
    background-color: white;
  }

  [v-cloak] {
    display: none;
  }
</style>
