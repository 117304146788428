import { isIos } from '@/utils/util'
export default {
  namespaced: true,
  state: {
    city: '',
    tabIndex: 0,
    productInfo: null,
    infoList: null,
    categoryInfo: null,
    agentID: null,
    splash: false,
    wxSignUrl: '',
    mark: false,
    firstUrl: '',
    backUrl: ''
  },
  mutations: {
    saveCity(state, city) {
      state.city = city
    },

    changeTabIndex(state, index) {
      state.tabIndex = index
    },

    saveProductInfo(state, info) {
      state.productInfo = info
    },

    saveInfoList(state, info) {
      state.infoList = info
    },

    saveCategoryInfo(state, info) {
      state.categoryInfo = info
    },

    saveAgentID(state, agentId) {
      state.agentID = agentId
    },

    saveSplash(state) {
      state.splash = true
    },

    setWechatSignUrl(state, wxSignUrl, canReturn) {
      // IOS仅记录第一次进入页面时的URL
      // IOS微信切换路由实际URL不变，只能使用第一进入页面的URL进行签名
      if (isIos() && (state.wxSignUrl !== '' || canReturn)) {
        return
      }
      state.wxSignUrl = wxSignUrl
      window.url = wxSignUrl
    },

    saveMark(state, mark) {
      state.mark = true
    },

    saveBackUrl(state, url) {
      state.backUrl = url
    },

    saveFirstUrl(state, url) {
      if (!state.firstUrl && url !== '/user/login') {
        state.firstUrl = url
      }
    }
  },
  actions: {},
  getters: {
    getWechatSignUrl: (state) => state.wxSignUrl
  }
}
