let mixin = {
  methods: {
    initWxShare() {
      let url = getWechatSignUrl()
      this.$store.commit('home/setWechatSignUrl', url)
      initWxSdk(this, this.wxSignUrl, document.title, SHARE_INFO.desc, location.href, SHARE_INFO.img) // 初始化微信分享
    }
  }
}

export { mixin }
