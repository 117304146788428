import Vue from 'vue'
import VueRouter from 'vue-router'
import webapp from 'rnjs-webapp'
import store from '../store/index'
import { isIos, getUserInfo } from '@/utils/util'
const { framePage, errorPage, beforeEach, afterEach } = webapp.routes

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '乐蜂享'
    },
    component: () => import('../views/index/Index.vue')
  },
  // {
  //   path: '/loan',
  //   name: 'loanIndex',
  //   meta: { title: '乐蜂享' },
  //   component: () => import('../views/index/LoanIndex.vue')
  // },
  {
    path: '/product/list',
    name: 'list',
    meta: { title: '产品列表' },
    component: () => import('../views/index/ProductList.vue')
  },
  {
    path: '/address/location',
    name: 'location',
    meta: {
      title: '地区选择'
    },
    component: () => import('../views/index/Location.vue')
  },
  {
    path: '/product/:sn',
    name: 'product',
    meta: {
      title: '产品详情'
    },
    component: () => import('../views/index/ProductDetail.vue')
  },
  {
    path: '/createPoster/:sn',
    name: 'createPoster',
    meta: {
      title: '海报'
    },
    component: () => import('../views/index/CreatePoster.vue')
  },
  {
    path: '/business/credit/:sn',
    name: 'apply',
    meta: {
      title: '报单入口'
    },
    component: () => import('../views/index/Apply.vue')
  },
  {
    path: '/agent/list',
    name: 'organization',
    meta: {
      title: '机构列表'
    },
    component: () => import('../views/index/OrganizationList.vue')
  },
  {
    path: '/agent/',
    name: 'agent',
    meta: {
      title: '机构首页'
    },
    component: () => import('../views/index/OrganizationIndex.vue')
  },
  {
    path: '/agent/introduction',
    name: 'introduction',
    meta: {
      title: '机构介绍'
    },
    component: () => import('../views/index/AgentIntroduction.vue')
  },
  {
    path: '/guaranty',
    name: 'guaranty',
    meta: {
      title: '抵押通道'
    },
    component: () => import('../views/index/Guaranty.vue')
  },
  {
    path: '/guaranty/detail',
    name: 'guarantyDetail',
    meta: {},
    component: () => import('../views/index/GuarantyDetail.vue')
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      title: '我的'
    },
    component: () => import('../views/my/User.vue')
  },
  {
    path: '/orderList/:index',
    name: 'orderlist',
    meta: {
      title: '未完成订单'
      // keepAlive: true
    },
    component: () => import('../views/my/OrderList.vue')
  },
  {
    path: '/orderdetail',
    name: 'detail',
    meta: {
      title: '订单详情'
    },
    component: () => import('../views/my/OrderDetail.vue')
  },
  {
    path: '/record',
    name: 'record',
    meta: {
      title: '记录'
    },
    component: () => import('../views/my/Record.vue')
  },
  {
    path: '/notification',
    name: 'notification',
    meta: {
      title: '消息通知'
    },
    component: () => import('../views/my/Notification.vue')
  },
  {
    path: '/qa',
    name: 'qa',
    meta: {
      title: '常见问题'
    },
    component: () => import('../views/my/QA.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于我们'
    },
    component: () => import('../views/my/About.vue')
  },
  {
    path: '/user/withhold',
    name: 'withhold',
    meta: {
      title: '电签'
    },
    component: () => import('../views/my/Withhold.vue')
  },
  {
    path: '/user/bindCard',
    name: 'bindCard',
    meta: {
      title: '绑定银行卡'
    },
    component: () => import('../views/my/BindBankCard')
  },
  {
    path: '/withhold/status',
    name: 'withholdStatus',
    meta: {
      title: '代扣状态'
    },
    component: () => import('../views/my/Status.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    meta: {
      title: '设置中心'
    },
    component: () => import('../views/my/Setting.vue')
  },
  {
    path: '/modify/:classify',
    name: 'modify',
    meta: {
      title: '修改昵称'
    },
    component: () => import('../views/my/Modify.vue')
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      title: '邀请好友'
    },
    component: () => import('../views/my/Share.vue')
  },
  {
    path: '/complete/:sn',
    name: 'complete',
    meta: {
      title: '申贷金额确认'
    },
    component: () => import('../views/my/Complete.vue')
  },
  {
    path: '/user/permission',
    name: 'permission',
    meta: {
      title: '权限控制'
    },
    component: () => import('../views/my/Permission.vue')
  },
  {
    path: '/user/login',
    name: 'login',
    meta: {
      title: '免密登录'
      // keepAlive: true
    },
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/user/mobile/bind',
    name: 'bind',
    meta: {
      title: '绑定手机号'
    },
    component: () => import('../views/login/BindPhone.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    meta: {
      title: '重置密码'
    },
    component: () => import('../views/login/Reset.vue')
  },
  {
    path: '/user/auth',
    name: 'auth',
    meta: {
      title: '实名认证'
    },
    component: () => import('../views/login/Auth.vue')
  },
  {
    path: '/team/:active',
    name: 'team',
    meta: {
      title: '团队'
    },
    component: () => import('../views/team/Index.vue')
  },
  {
    path: '/add',
    name: 'add',
    meta: {
      title: '客户详情'
    },
    component: () => import('../views/team/AddClient.vue')
  },
  {
    path: '/poster',
    name: 'poster',
    meta: {
      title: '分享'
    },
    component: () => import('../views/team/Poster.vue')
  },
  {
    path: '/chooseposter',
    name: 'chooseposter',
    meta: {
      title: '海报库'
    },
    component: () => import('../views/team/ChoosePoster.vue')
  },
  {
    path: '/poster/list/:id',
    name: 'posterlist',
    meta: {
      title: '海报列表'
    },
    component: () => import('../views/team/PosterList.vue')
  },
  {
    path: '/discovery',
    name: 'discovery',
    meta: {
      title: '发现'
    },
    component: () => import('../views/discovery/Index.vue')
  },
  {
    path: '/information',
    name: 'information',
    meta: {
      title: '资讯'
    },
    component: () => import('../views/discovery/information/Index.vue')
  },
  {
    path: '/article/:id',
    name: 'article',
    meta: {
      title: '文章详情'
    },
    component: () => import('../views/discovery/information/Article.vue')
  },
  {
    path: '/task',
    name: 'task',
    meta: {
      title: '任务积分'
    },
    component: () => import('../views/mall/Task.vue')
  },
  {
    path: '/integral/water',
    name: 'integralWater',
    meta: {
      title: '积分流水'
    },
    component: () => import('../views/mall/IntegralWater.vue')
  },
  {
    path: '/goods/list',
    name: 'goodsList',
    meta: {
      title: '商品列表'
    },
    component: () => import('../views/mall/integralMall/GoodsList.vue')
  },
  {
    path: '/goods/detail/:id',
    name: 'goodsDetail',
    meta: {
      title: ''
    },
    component: () => import('../views/mall/integralMall/GoodsDetail.vue')
  },
  {
    path: '/goods/settlement/:id',
    name: 'settlement',
    meta: {
      title: '结算'
    },
    component: () => import('../views/mall/integralMall/Settlement.vue')
  },
  {
    path: '/status',
    name: 'status',
    meta: {
      title: '乐蜂享'
    },
    component: () => import('../views/mall/integralMall/Status.vue')
  },
  {
    path: '/order/list',
    name: 'orderList',
    meta: {
      title: '订单列表'
    },
    component: () => import('../views/mall/integralOrder/OrderList.vue')
  },
  {
    path: '/order/detail/:id',
    name: 'orderDetail',
    meta: {
      title: '订单详情'
    },
    component: () => import('../views/mall/integralOrder/OrderDetail.vue')
  },
  {
    path: '/dadao',
    name: 'dadao',
    meta: {
      title: '大道金服'
    },
    component: () => import('../views/special/Dadao.vue')
  },
  {
    path: '/application/inxi',
    name: 'inxi',
    meta: {
      title: '映西税贷'
    },
    component: () => import('../views/special/ApplicationInxi.vue')
  },
  {
    path: '/connection',
    name: 'connection',
    meta: {
      title: '人脉库'
    },
    component: () => import('../views/connections/Index.vue')
  },
  {
    path: '/connection/card/:sn',
    name: 'connectionCard',
    meta: {
      title: '名片'
    },
    component: () => import('../views/connections/Connection.vue')
  },
  {
    path: '/connection/modify',
    name: 'modifyConnection',
    meta: {
      title: '修改名片'
    },
    component: () => import('../views/connections/CardInfo.vue')
  },
  {
    path: '/calculator',
    name: 'calculator',
    meta: {
      title: '计算器',
      keepAlive: true
    },
    component: () => import('../views/discovery/calculator/Calculator.vue')
  },
  {
    path: '/calculator/detail',
    name: 'calculatorDetail',
    meta: {
      title: '还款明细'
    },
    component: () => import('../views/discovery/calculator/CalculatorDetail.vue')
  },
  {
    path: '/collectTicket',
    name: 'collectTicket',
    meta: {
      title: '乐蜂享'
    },
    component: () => import('../views/discovery/CollectTickets.vue')
  }
]

// 获取真实有效微信签名URL
function getWechatSignUrl(to) {
  if (isIos()) {
    return window.location.href.split('#')[0]
  } else {
    // 此处$appHost需要自行处理
    return (location.protocol + '//' + location.host + '/m' + (to.fullPath === '/' ? '' : to.fullPath)).split('#')[0]
  }
}

const router = new VueRouter({
  mode: 'history',
  base: window.VUE_APP_ROUTER_BASE || '/',
  routes: [...framePage, ...routes, ...errorPage]
})

router.beforeEach((to, from, next) => {
  return beforeEach(store, router, to, from, next)
})

router.afterEach((to, from) => {
  let url = getWechatSignUrl(to)
  store.commit('home/setWechatSignUrl', url)
  return afterEach(store, router, to, from)
})

export default router
