export default {
  getLoginParams: '/user/login', // 获取登录页面配置参数
  login: '/user/login', // 登录请求
  getUserInfo: '/user/info', // 登录时获取头像nickname和是否实名等信息
  getSMS: '/sms/send', // 获取短信验证码
  findpwd: '/user/findpwd', // 找回密码或修改密码
  geetestValidate: '/libs/gtcaptcha/gtValidate', // 进行极验验证2次验证
  getContent: '/user/content',
  getProductList: '/product/list', // 获取产品列表
  auth: '/user/auth', // 实名认证
  getQuestion: '/user/faq', // 常见问题
  getMenu: '/user/index', // 获取我的页面菜单
  getOrderList: '/order/list', // 获取订单列表
  getNotification: '/user/notifyList', // 获取通知列表
  getSetting: '/user/settingsMenu', // 获取设置菜单
  getSortClassify: '/index', // 获取列表页索引分类
  getProductDetail: '/product/detail', // 获取产品详情
  getCredit: '/business/credit', // 获取信贷报单页信息
  getLocation: '/address/location', // 获取城市定位
  modifyPass: '/user/password', // 修改密码
  modifyNickname: '/user/nickname', // 设置用户名
  getShareInfo: '/user/invitation/share', // 获取分享信息
  complete: '/order/complete', // 订单完成
  aboutUs: '/user/about_us', // 关于我们
  logout: '/user/logout', // 登出
  getOcrToken: '/user/ocrToken', // 获取ocrtoken
  esign: '/order/esignStart', // 电签
  esignStart: '/product/esignStart', // 新版电签
  readMessage: '/user/read_message', // 阅读站内信
  modifyAvatar: '/user/avatar', // 修改头像
  deleteOrder: '/order/soft/', // 删除订单
  getOrderDetail: '/order/detail', // 获取订单详情
  getBusiness: '/business/listV2', // 获取首页业务分类
  getOrganizations: '/agent/list', // 获取机构列表
  getOrganizationDetail: '/agent', // 获取机构详情
  getTeam: '/user/team', // 获取团队列表
  getClient: '/user/client', // 获取客户列表
  getClientDetail: '/user/client/detail', // 获取客户详情
  getClientConfig: '/user/client/config', // 获取客户详情页面配置,
  getUserByMobile: 'user/byMobile', // 根据手机号查询用户信息
  getPermissionBusiness: '/business/credit/list', // 获取权限控制的产品列表
  permissionAuth: '/business/credit/authorize', // 开通渠道权限
  getSplash: '/agent/startImage', // 获取启动图
  proxy: '/order/proxy', // 获取config配置
  // housingMortgage: '/order/housingMortgage', // 上传通道业务数据
  getProxy: '/product/proxy', // 获取通道首页数据
  getWxParams: '/wechat/browserConfig',
  getPosterCategory: '/poster/category', // 获取海报分类
  searchPoster: '/poster/categoryLike', // 搜索海报
  getPosterList: '/poster/list', // 获取海报列表
  getPoster: '/poster', // 获取海报
  getNewsList: '/news', // 获取新闻列表
  getNewsDetail: '/news/detail', // 获取新闻详情
  addComment: '/news/comment', // 发表评论或获取评论
  searchNews: '/news/titleLike', // 搜索新闻
  getNewsHistory: '/news/userHistory', // 获取新闻历史
  getCommentHistory: '/news/userComment', // 获取评论历史
  getIntegralWater: '/task/pointLog', // 获取积分流水
  getTaskList: '/task', // 任务列表
  getTakeFinish: '/task/finish', // 领取用户积分
  getMallIndex: '/mall/index', // 获取积分商城首页数据
  getMallList: '/mall/goods/list', // 获取积分商城列表分页数据
  getMallDetail: '/mall/goods/detail', // 获取商品详情信息
  createMallOrder: '/mall/order/create', // 创建商城订单
  getCreateResult: '/mall/order/create/result', // 查询订单创建结果
  getOrderCatrgory: '/mall/order', // 获取订单分类
  getMallOrderDetail: '/mall/order/detail', // 获取商城订单详情
  receiptOrder: '/mall/order/receipt', // 商城订单接收
  getMallOrderList: '/mall/order/list', // 获取商城订单分页列表
  daDao: '/order/daDao', // 提交大道打包信息
  getInfoOfInxi: '/application/inxi', // 获取inxi税贷的短信验证码类型
  postInxiInfo: '/order/inxi', // 上传映西税贷资料
  vertifyBank: '/order/validateBankAccount', // 验证银行卡
  bankSmscode: '/order/bindBankAccount', // 获取绑定银行卡验证码
  bindBank: '/order/confirmBankSign', // 绑定银行卡
  getWithholdOrder: '/order/repayment', // 获取代扣订单信息
  withholdPay: 'order/pay', // 支付代扣
  getWithholdStatus: '/order/payStatus', // 获取代扣状态
  getDiscovery: '/discover', // 获取发现页信息
  getConnectionInfo: '/user/info', // 获取人脉库信息
  getConnectionList: 'user/info/list', // 获取人脉库列表
  buyInfo: '/user/info/buyInfo', //购买用户信息
  getCardInfo: '/user/info/detail', // 获取名片详情
  getTags: '/user/info/labels', // 获取标签
  getNeeds: '/user/info/demand', // 获取需求列表
  submitCard: '/user/info/update', // 提交名片信息
  calculator: '/discover/calculator', // 获取计算机选项
  calculatorDetail: '/discover/calculatorDetail', // 获取计算明细
  posterProduct: '/poster/product', // 获取海报模板列表
  getTickets: '/discover/invoiceTool', // 获取采票信息
  getaaa: 'zzz'
}
