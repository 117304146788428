let periods = []
for (let i = 0; i <= 72; i = i + 6) {
  i ? periods.push(i) : false
}
let days = []
for (let i = 1; i <= 28; i++) {
  days.push(i)
}
const Fields = [
  [
    {
      title: '借款信息',
      tip: '请查看产品详情',
      fields: [
        {
          type: 'text',
          label: '借款产品名称',
          placeholder: '请输入产品名称',
          value: '',
          readonly: true
        },
        {
          type: 'text',
          label: '借款利率类型',
          placeholder: '请选择借款利率类型',
          value: '',
          readonly: true
        },
        {
          type: 'text',
          label: '借款利息费率(%)',
          placeholder: '请输入借款利息费率',
          value: '',
          readonly: true
        },
        {
          type: 'text',
          label: '借款罚息费率(%)',
          placeholder: '请输入借款罚息费率',
          value: '',
          readonly: true
        },
        {
          type: 'text',
          label: '借款用途',
          placeholder: '请选择借款用途',
          value: '资金周转',
          readonly: true
        }
      ]
    }
  ],
  [
    {
      title: '客户信息',
      tip: '请录入真实有效的客户借款信息',
      fields: [
        {
          type: 'text',
          label: '借款人姓名',
          placeholder: '请输入借款人姓名',
          require: true,
          value: ''
        },
        {
          type: 'text',
          label: '借款人身份证',
          placeholder: '请输入借款人身份证',
          require: true,
          value: ''
        },
        {
          type: 'text',
          label: '借款人手机号',
          placeholder: '请输入借款人手机号',
          maxlength: 11,
          require: true,
          value: ''
        },
        {
          type: 'text',
          label: '借款人邮箱账号',
          placeholder: '请输入借款人邮箱账号',
          value: ''
        },
        {
          type: 'text',
          label: '借款人地址',
          placeholder: '请输入借款人地址',
          value: ''
        }
      ]
    },
    {
      title: '共借信息',
      tip: '请录入真实有效的客户借款信息',
      fields: [
        {
          type: 'text',
          label: '',
          value: '+',
          align: 'center',
          readonly: true,
          'font-size': '25px'
        }
      ]
    },
    {
      title: '备注字段',
      tip: '选填项，用于填充合同里的权属编号',
      fields: [
        {
          type: 'textarea',
          placeholder: '请输入备注字段...',
          value: '',
          align: 'left',
          height: '146px'
        }
      ]
    }
  ],
  [
    {
      title: '收款信息',
      tip: '请录入真实有效的客户收款信息',
      fields: [
        // {
        //   type: 'text',
        //   label: '商户订单时间',
        //   placeholder: '请选择商户订单时间',
        //   value: ''
        // },
        {
          type: 'text',
          label: '收款户名',
          placeholder: '请输入收款户名',
          require: true,
          value: ''
        },
        {
          type: 'text',
          label: '收款人身份证',
          placeholder: '请输入收款人身份证',
          require: true,
          value: ''
        },
        {
          type: 'text',
          label: '收款人手机号',
          placeholder: '请输入收款人手机号',
          maxlength: 11,
          require: true,
          value: ''
        },
        {
          type: 'text',
          label: '借款到账银行卡号',
          placeholder: '请输入借款到账银行卡号',
          require: true,
          value: ''
        }
      ]
    },
    {
      title: '还款信息',
      tip: '请录入真实有效的还款详情信息',
      fields: [
        {
          type: 'text',
          label: '还款卡号',
          placeholder: '请输入还款卡号',
          value: ''
        },
        {
          type: 'text',
          label: '还款持卡人姓名',
          placeholder: '请输入还款持卡人姓名',
          value: ''
        },
        {
          type: 'text',
          label: '还款卡预留手机号',
          placeholder: '请输入还款卡预留手机号',
          maxlength: 11,
          value: ''
        }
      ]
    }
  ],
  [
    {
      title: '详情信息',
      tip: '请录入真实有效的还款计划信息',
      fields: [
        {
          type: 'number',
          label: '借款金额(元)',
          placeholder: '请输入借款金额',
          require: true,
          value: ''
        },
        {
          type: 'picker',
          label: '分期期数',
          placeholder: '请选择借款分期期数',
          require: true,
          value: '',
          column: periods,
          readonly: true
        },
        {
          type: 'picker',
          picker: 'date',
          label: '借款开始日期',
          require: true,
          placeholder: '请选择借款开始日期',
          minDate: new Date(2020, 0, 1),
          maxDate: new Date(2099, 10, 1),
          value: '',
          readonly: true
        },
        {
          type: 'picker',
          picker: 'date',
          label: '借款到期日期',
          require: true,
          placeholder: '请选择借款到期日期',
          minDate: new Date(2020, 0, 1),
          maxDate: new Date(2099, 10, 1),
          value: '',
          readonly: true
        },
        // {
        //   type: 'text',
        //   label: '借款期限(天)',
        //   placeholder: '请输入借款期限',
        //   value: ''
        // },
        {
          type: 'picker',
          label: '还款方式',
          placeholder: '请选择还款方式',
          require: true,
          column: ['等本等息', '先息后本', '自行编辑'],
          value: '',
          readonly: true
        },
        {
          type: 'picker',
          label: '还款日',
          placeholder: '请输入还款日',
          require: true,
          value: '',
          column: days,
          readonly: true
        }
      ]
    },
    {
      title: '还款计划',
      tip: '请核对真实有效的还款详情信息',
      type: 'collapse',
      accordion: true,
      plan: []
    }
  ],
  [
    {
      title: '借款合同',
      tip: '请上传借款合同照片',
      require: true,
      type: 'image/*',
      value: []
    },
    {
      title: '产调材料',
      tip: '请上传产调材料照片',
      require: true,
      type: 'image/*',
      value: []
    },
    {
      title: '电子签章',
      tip: '请上传电子签章照片',
      require: true,
      type: 'image/*',
      value: []
    },
    {
      title: '借款人照片',
      tip: '请上传借款人手持借款合同、身份证照片',
      require: true,
      type: 'image/*',
      value: []
    },
    {
      title: '授权委托书',
      tip: '请上传授权委托书照片',
      name: 'authorization',
      require: true,
      type: 'image/*',
      value: []
    },
    {
      title: '居间合同',
      tip: '请上传照片',
      name: 'contract',
      require: true,
      type: 'image/*',
      value: []
    },
    {
      title: '签约视频',
      tip: '请上传签约视频（最大上传文件大小：20MB，建议720p）',
      name: 'video',
      require: true,
      type: 'video/*',
      value: []
    }
  ]
]

export { Fields }
