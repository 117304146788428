import { LOGIN_TYPE } from '@/utils/enum.js'

export default {
  namespaced: true,
  state: {
    loginType: LOGIN_TYPE['phone-login'],
    forget: false,
    loginApiRes: null, // 登录页面获取到的通用数据
    tel: '',
    password: ''
  },
  mutations: {
    changeLoginType(state, data) {
      logs(data)
      state.loginType = data
    },
    changeForget(state) {
      state.forget = !state.forget
    },
    saveLoginApiRes(state, data) {
      state.loginApiRes = data
    },
    saveTel(state, data) {
      state.tel = data
    },
    savePass(state, data) {
      state.password = data
    }
  }
}
