const LOGIN_TYPE = {
  'phone-login': 1, // 短信登录
  'password-login': 2, // 密码登录
  'wx-login': 3, // 微信登录
  forget: 4, // 忘记密码
  bind: 5, // 绑定手机号,
  reset: 6 // 重置密码
}

const COLORS = {
  theme: 'rgba(16, 164, 254, 1)',
  disable: 'rgba(201, 201, 201, 1)',
  textGrey: 'rgba(167,167,167,1)',
  skyblue: 'rgba(139, 205, 254, 1)'
}

const HANDLES = {
  viewOrder: 'VIEW',
  exchangeAgain: 'EXCHANGE',
  contactCustomer: 'CUSTOMER',
  confirm: 'CONFIRM'
}

const SHARE_INFO = {
  desc: '分享身边每个人',
  img: 'https://lfxdev.res.inxi.net/file/20210128/16118258065170.jpg?imageView2/1/w/100/h/100/q/75|imageslim'
}

const IMAGE_PATH = '@/assets/imgs/'
const LOCAL_IMAGE = {
  empty: 'empty.png',
  phone: 'phone.png'
}

for (const key in LOCAL_IMAGE) {
  LOCAL_IMAGE[key] = IMAGE_PATH + LOCAL_IMAGE[key]
}

const SENSITIVE_KEY_WORD = [
  '18禁',
  'a片',
  'cao你',
  'fuck',
  'gay片',
  'g点',
  'g片',
  'h动画',
  'h动漫',
  'sb',
  '暴乳',
  '暴淫',
  '爆草',
  '爆你菊',
  '爆乳',
  '被操',
  '被插',
  '被干',
  '屄',
  '逼奸',
  '婊子',
  '婊子养的',
  '别他吗',
  '操逼',
  '操黑',
  '操烂',
  '操你大爷',
  '操你妈',
  '操你娘',
  '操你全家',
  '插b',
  '插暴',
  '插逼',
  '插比',
  '插进',
  '插你',
  '插我',
  '插阴',
  '刹笔',
  '潮吹',
  '潮喷',
  '成人dv',
  '成人电',
  '成人电影',
  '成人卡通',
  '成人聊',
  '成人论坛',
  '成人片',
  '成人色情',
  '成人视',
  '成人图',
  '成人文',
  '成人小说',
  '成人游戏',
  '吃精',
  '赤裸',
  '抽插',
  '抽一插',
  '春药',
  '大sb',
  '放尿',
  '肥逼',
  '粉穴',
  '风骚',
  '风月大陆',
  '封面女郎',
  '干你妈',
  '干你娘',
  '干死你',
  '干穴',
  '肛交',
  '肛门',
  '狗操',
  '狗草',
  '狗娘养',
  '狗日的',
  '狗杂种',
  '龟头',
  '国产av',
  '豪乳',
  '黑逼',
  '后庭',
  '后穴',
  '虎骑',
  '黄片',
  '混蛋',
  '鸡巴',
  '鸡吧',
  '鸡奸',
  '激情',
  '几吧',
  '妓女',
  '寂寞男',
  '寂寞女',
  '奸情',
  '贱b',
  '贱比',
  '贱货',
  '贱人',
  '脚交',
  '口爆',
  '口活',
  '口交',
  '口射',
  '口淫',
  '裤袜',
  '狂操',
  '狂插',
  '狼友',
  '浪逼',
  '浪妇',
  '浪叫',
  '浪女',
  '聊性',
  '凌辱',
  '漏乳',
  '露b',
  '乱奸',
  '乱交',
  '乱伦',
  '轮暴',
  '轮操',
  '轮奸',
  '裸露',
  '裸陪',
  '妈逼',
  '妈了个逼',
  '麻痹的',
  '马勒',
  '美逼',
  '美乳',
  '美少妇',
  '美腿',
  '美穴',
  '美幼',
  '媚外',
  '迷奸',
  '秘唇',
  '密穴',
  '蜜穴',
  '蜜液',
  '摸奶',
  '摸胸',
  '母奸',
  '奶子',
  '男奴',
  '内射',
  '嫩逼',
  '嫩女',
  '嫩穴',
  '屁眼',
  '欠干',
  '强jian',
  '强奸处女',
  '情趣用品',
  '情色',
  '全家不得好死',
  '全家死光',
  '全家死绝',
  '拳交',
  '人兽',
  '人渣',
  '日逼',
  '日烂',
  '日你妈',
  '揉乳',
  '肉棒',
  '肉逼',
  '肉唇',
  '肉洞',
  '肉缝',
  '肉棍',
  '肉茎',
  '肉具',
  '肉穴',
  '肉欲',
  '乳爆',
  '乳房',
  '乳沟',
  '乳交',
  '乳头',
  '三级片',
  '骚逼',
  '骚比',
  '骚妇',
  '骚货',
  '骚浪',
  '骚女',
  '骚水',
  '骚穴',
  '骚嘴',
  '色b',
  '色逼',
  '色界',
  '色猫',
  '色盟',
  '色情网站',
  '色区',
  '色色',
  '色诱',
  '色欲',
  '杀b',
  '沙比',
  '傻b',
  '傻逼',
  '傻比',
  '煞逼',
  '煞笔',
  '射爽',
  '射颜',
  '爽片',
  '丝袜',
  '丝诱',
  '死逼',
  '死全家',
  '套弄',
  '体奸',
  '体位',
  '舔脚',
  '舔阴',
  '调教',
  '偷欢',
  '偷拍',
  '推油',
  '脱内裤',
  '我操',
  '我草',
  '我日你',
  '卧槽',
  '卧艹',
  '无耻',
  '无码',
  '无修正',
  '下贱',
  '下体',
  '相奸',
  '小xue',
  '小逼',
  '小穴',
  '校鸡',
  '性伴侣',
  '性饥渴',
  '性技巧',
  '性交',
  '性奴',
  '性虐',
  '性息',
  '性欲',
  '胸推',
  '穴口',
  '穴图',
  '颜射',
  '艳情小说',
  '阳具',
  '阴b',
  '阴部',
  '阴唇',
  '阴道',
  '阴蒂',
  '阴阜',
  '阴核',
  '阴户',
  '阴茎',
  '阴精',
  '阴毛',
  '陰唇',
  '陰道',
  '陰戶',
  '淫虫',
  '淫荡',
  '淫荡美女',
  '淫荡视频',
  '淫荡照片',
  '淫妇',
  '淫河',
  '淫贱',
  '淫叫',
  '淫浪',
  '淫乱',
  '淫媚',
  '淫騷妹',
  '淫色',
  '淫兽',
  '淫兽学',
  '淫书',
  '淫水',
  '淫娃',
  '淫威',
  '淫亵',
  '淫穴',
  '淫样',
  '淫液',
  '淫照',
  '应召',
  '幼交',
  '幼男',
  '幼女',
  '玉乳',
  '玉穴',
  '欲火',
  '欲女',
  '欲仙欲死',
  '原味内衣',
  '援交',
  '援助交际',
  '招鸡',
  '招妓',
  '真他妈',
  '中年美妇',
  '抓胸',
  '装b',
  '自拍',
  '自慰',
  '作爱'
]

const DADAO_UPLOAD_TYPE = { 1: '身份证', 2: '结婚证', 3: '离婚证', 4: '离婚协议', 5: '房产证', 6: '户口本' }

export { LOGIN_TYPE, COLORS, LOCAL_IMAGE, HANDLES, SHARE_INFO, SENSITIVE_KEY_WORD, DADAO_UPLOAD_TYPE }
